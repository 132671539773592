<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">新增农事任务</div>
    <div style="margin:20px 0 30px 20px ">
      <el-button type="primary" @click="dialogVisible = true" v-if="this.$getPermission('/pc/farming/save')">新增任务</el-button>
      <el-dialog
          title="添加任务"
          :visible.sync="dialogVisible"
          width="30%"

      >
        <div style="" class="dialogBox">
          <div class="dialogBox-con">
            <el-form label-width="100px">
              <el-form-item label="任务类型">
                <el-select v-model="value" placeholder="请选择"  @change="getTypeName(value)">
                  <el-option
                      v-for="item in optionList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="任务详情">
                <el-input v-model="details" type="textarea" style="width: 92%;"></el-input>
              </el-form-item>
              <el-form-item label="完工日期" prop="dueDate">
                <el-date-picker

                    v-model="dueDate"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="任意时间点">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="大棚" prop="gIds">
                <el-select v-model="greenHouseNames" multiple collapse-tags placeholder="请选择大棚" >
                  <el-option
                      v-for="item in selectList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="执行人" prop="userId">
                <el-select v-model="userNames" multiple collapse-tags placeholder="请选择执行人" >
                  <el-option
                      v-for="item in adminList"
                      :key="item.id"
                      :label="item.username"
                      :value="item.username">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <div v-for="(item,index) in AfarmingCapital" :key="index" style="margin-top: 5px">
                  {{ item.name }}:
                  <el-input style="width: 200px;" name="inputName" v-model="selectNumList[index]"
                            @change="joinList($event,item.id)"></el-input>
                  {{ getUnti(item.name) }}<br>
                </div>
              </el-form-item>

              <el-form-item label="所需农资" v-show="if_type == 0">
                <el-tree
                    :data="agriculturalList"
                    show-checkbox
                    empty-text="暂无数据"
                    default-expand-all
                    node-key="id"
                    ref="tree"
                    @check-change="handleNodeClick"
                    highlight-current
                    :check-strictly="true"
                    :check-on-click-node="true"
                    :props="defaultProps">
                </el-tree>
              </el-form-item>
              <el-form-item label="农资选择" v-show="if_type == 1">
                <el-select v-model="farmingSelecttype" filterable placeholder="请选择" style="width: 130px;"
                           @change="getSelectproductTypeList">
                  <el-option
                      v-for="item in productTypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-select v-model="farmingSelectName" multiple filterable collapse-tags placeholder="请选择"
                           style="width: 160px;margin-left: 10px" @change="changeNameListFunc()">
                  <el-option
                      v-for="item in farmingSelecttypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button class="dialog-button" @click="update">提交</el-button>
              </el-form-item>
            </el-form>

          </div>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          style="width: 918px;margin-left: 36px;margin-bottom: 30px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="typeName"
            label="任务类型"
        >
        </el-table-column>
        <el-table-column
            prop="details"
            label="任务详情">
        </el-table-column>
        <el-table-column
            prop="dueDate"
            label="任务截止时间">
        </el-table-column>
        <el-table-column
            prop="greenHouseNames"
            label="大棚">
        </el-table-column>
        <el-table-column
            prop="userNames"
            label="执行人">
        </el-table-column>
        <el-table-column
            prop="farmingCapital"
            :formatter="fromFarming"
            label="农资">
        </el-table-column>
        <el-table-column
            v-if="this.$getPermission('/pc/farming/delete')"
            label="操作">
          <template slot-scope="scope">
            <el-popconfirm style="margin-left: 10px"
                           title="确定删除吗？"
                           @confirm="handleDelete(scope.$index, scope.row)"
            >
              <a style="cursor: pointer;color:#1A99F2 "
                 type="danger"
                 slot="reference"
              >删除
              </a>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-left: 30px;margin-bottom: 10px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    productTypeList: {
      type: Array,
      defalut: []
    }
  },
  inject: [
    'reload'
  ],
  data() {
    return {
      optionList: [],
      selectList: [],
      adminList: [],
      agriculturalList: [],
      defaultProps: {
        children: 'capitalEntityList',
        label: 'name'
      },

      num: '',
      value: '',
      AfarmingCapital: [],
      selectNumList: [],
      untiList: [],
      farmingAddDtoList: [],
      if_type: 0,
      dueDate: '',
      dialogVisible: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      userId: JSON.parse(localStorage.getItem('user_nongye')).userId,
      "details": "",
      "farmingCapital": "",
      "greenHouseNames": [],
      "picUrl": "",
      "result": "",
      "status": '',
      "typeId": '',
      "userNames": '',

      farmingSelecttype: '',
      farmingSelectName: '',
      farmingSelecttypeList: []

    }
  },
  mounted() {
    this.getDataList()
    this.getSelectList()
    localStorage.setItem('selectIndex', '1')
  },
  methods: {
    changeNameListFunc() {
      var dataList = []
      if (this.farmingSelectName.length !== 0) {
        for (let i = 0; i < this.farmingSelectName.length; i++) {
          let ida = this.farmingSelecttypeList.findIndex((itm) => itm.id === this.farmingSelectName[i])
          let idx = dataList.findIndex((item) => item.id === this.farmingSelecttypeList[ida].id)
          if (idx === -1) {
            dataList.push(this.farmingSelecttypeList[ida])
          } else {
            dataList.splice(idx, 1)
          }
          this.AfarmingCapital = dataList
        }
      } else {
        this.AfarmingCapital = []
      }

    },
    getTypeName(res) {
      let idx = this.optionList.findIndex((itm) => itm.id === res)
      console.log(idx, 'oidx')
      this.if_type = this.optionList[idx].type
      this.AfarmingCapital = []

    },
// 第一个下拉框选择
    getSelectproductTypeList(res) {
      console.log(res,)
      let params = {
        "pId": res
      }
      this.$api.post('prod/getPros', params, res => {
        console.log(res, '选中农产品分类返回农产品名字')
        if (res.code === 0) {
          this.farmingSelecttypeList = res.data
          this.farmingSelectName = ''
        }

      })
    },
    handleNodeClick(node) {
      console.log(node, this.AfarmingCapital, 'this.AfarmingCapital====================')
      let idx = this.AfarmingCapital.findIndex((item) => item.id == node.id)
      console.log(idx)
      if (idx === -1) {
        this.AfarmingCapital.push(node)
      } else {
        this.AfarmingCapital.splice(idx, 1)
      }
    },
    joinList(res, data) {
      console.log(res, 'ssss', data)
      let idx = this.farmingAddDtoList.findIndex((itm) => itm.farmingId === data)
      if (idx === -1) {
        this.farmingAddDtoList.push({
          farmingId: data,
          num: Number(res),
        })
      }

      console.log(this.farmingAddDtoList)
    },
    getUnti(data) {
      for (let i = 0; i < this.AfarmingCapital.length; i++) {
        if (this.AfarmingCapital[i].name === data) {
          return this.AfarmingCapital[i].unit
        }
      }
    },
    update() {
      let params = {
        "details": this.details,
        "dueDate": this.dueDate,
        "farmingAddDtoList": this.farmingAddDtoList,
        "greenHouseNames": this.greenHouseNames.join('、'),
        "status": 0,
        "typeId": this.value,
        "userNames": this.userNames.join('、')
      }

      this.$api.post('farming/save', params, res => {
        // console.log(res, '数据返回')
        if (res.code === 0) {
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'success',
            onClose: () => {
              this.dialogVisible = false
              this.reload()
            }

          });
        }

      })
    },
    fromFarming(row) {
      if (row.farmingCapital) {
        let data = JSON.parse(row.farmingCapital)
        // eslint-disable-next-line no-unused-vars
        var farming = ''
        for (let i = 0; i < data.length; i++) {
          farming += data[i].farmingName + '  ' + data[i].num + ' ' + data[i].unit + '  ,'
        }
        return farming.slice(0, farming.length - 1)
      } else {
        return ''
      }
    },
    getDataList() {
      let params = {
        "page": this.pageIndex,
        "size": this.pageSize,
      }

      this.$api.post('farming/getMyList', params, res => {
        // console.log(res, '数据返回')
        if (res.code === 0) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }

      })
    },

    getSelectList() {
      // 任务类别
      this.$api.post('farmingType/select', {}, res => {
        // console.log(res, '数据返回')
        if (res.code === 0) {
          this.optionList = res.data
        }

      })
      // 大棚列表
      this.$api.post('gHouse/select', {}, res => {
        // console.log(res, '数据返回')
        if (res.code === 0) {
          this.selectList = res.data
        }

      })
      // 执行人列表
      this.$api.post('user/select', {}, res => {
        // console.log(res, '数据返回')
        if (res.code === 0) {
          this.adminList = res.data
        }

      })
      // 农资类型
      this.$api.get('farmingCapital/select2', {}, res => {
        // console.log(res, '数据返回')
        var dataList = []
        if (res.code === 0) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].disabled = true
            dataList.push(res.data[i])
          }
          console.log(dataList, 'dataList')
          this.agriculturalList = dataList
        }

      })

    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
      this.$api.post('farming/delete', {id: row.id}, res => {
        console.log(res, '删除', this.tableData)
        if (res.code === 0) {
          let idx = this.tableData.findIndex((item) => item.id === row.id)
          console.log(idx)
          this.tableData.splice(idx, 1)
        }

      })
    },
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}

.table-title-name {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin: 20px 0 5px 36px;

}

.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}

.dialog-button {
  height: 50px;
  background: #009400;
  border-radius: 30px;
  width: 80%;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}

.dialogBox {
  padding: 10px 40px 10px 40px;
  min-height: 580px;
  overflow: hidden;
  position: relative;
}
.dialogBox-con{
  position: absolute;
  left: 0;
  top: 0;
  right: -17px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;

}
</style>
