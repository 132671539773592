<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">任务结果汇报</div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <el-carousel >
        <el-carousel-item v-for="item in dataImgLIst" :key="item">
          <img :src="item" alt=""  height="100%" >
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
    <div style="margin: 30px 0 30px 20px  ">
      <el-table
          :data="tableData"
          border
          style="width: 950px;font-size: 14px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="typeName"
            label="任务类型"
        >
        </el-table-column>
        <el-table-column
            prop="details"
            label="任务详情">
        </el-table-column>
        <el-table-column
            prop="dueDate"
            label="截止时间">
        </el-table-column>
        <el-table-column
            prop="greenHouseNames"
            label="大棚">
        </el-table-column>
        <el-table-column
            prop="userNames"
            label="执行人">
        </el-table-column>
        <el-table-column
            prop="farmingCapital"
            :formatter="fromFarming"
            label="农资">

        </el-table-column>
        <el-table-column
            prop="status"
            :formatter="fromStatus"
            label="任务状态">
        </el-table-column>
        <el-table-column
            prop="finishTime"
            label="完成时间">
        </el-table-column>
        <el-table-column
            prop="result"
            label="结果汇报">
        </el-table-column>
        <el-table-column
            label="图片">
          <template slot-scope="scope">
            <a style="cursor: pointer;color:#1A99F2 "
               @click="handleEdit(scope.$index, scope.row)">查看
            </a>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-left: 30px;margin-bottom: 10px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dialogVisible: false,
      dataImgLIst: [],
      userId: JSON.parse(localStorage.getItem('user_nongye')).userId,
    }
  },
  mounted() {
    this.getDataList()
    localStorage.setItem('selectIndex','2')
  },
  methods: {
    fromStatus(row) {
      return row.status == 0 ? '待完成' : row.status == 1 ? '已完成' : '未完成'
    },
    fromFarming(row) {
      if (row.farmingCapital) {
        let data = JSON.parse(row.farmingCapital)
        console.log(data, '222')
        // eslint-disable-next-line no-unused-vars
        var farming = ''
        for (let i = 0; i < data.length; i++) {
          farming += data[i].farmingName + '  ' + data[i].num + ' ' + data[i].unit + '  ,'
        }
        return farming.slice(0,farming.length-1)
      } else {
        return ''
      }

    },
    getDataList() {
      let params = {
        "page": this.pageIndex,
        "size": this.pageSize,
      }

      this.$api.post('farming/getList', params, res => {
        // console.log(res, '数据返回')
        if (res.code === 0) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }

      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    handleEdit(index, row) {  // 修改按钮
      console.log(index, row.id);
      if (row.picUrl) {
        this.dataImgLIst = JSON.parse(row.picUrl)
        this.dialogVisible = true
      }
    },
    handleClose(res) {  // 修改按钮
      console.log(
          res
      )
      this.dataImgLIst =[]
      this.dialogVisible = false
    },
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
</style>
